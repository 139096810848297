import { insertCss } from "insert-css";
import { connectToChild } from "penpal";
import whenDomReady from "when-dom-ready";

import type {
  columnWidthType,
  CsvData,
  customTextType,
  DynamicColumnsType,
  fileFormats,
  Language,
  onDataResponseWithBatching,
  onDataResponseWithoutBatching,
  onErrorType,
  onFileUploadType,
  onMatchColumnsType,
  onRecordEditType,
  onRecordsInitialType,
  ThemeType,
} from "./types";

// eslint-disable-next-line dot-notation
const MOUNT_URL = process.env["MOUNT_URL"] || "http://localhost:3000/importer";

const insertIframe = (id: string, importerDisplay: "inline" | "modal", iframeId?: string) => {
  const usecsvInlineWrapper = document.getElementById("usecsv-importer-inline-wrapper");

  const usecsvContainerStyles =
    importerDisplay === "modal" || !usecsvInlineWrapper
      ? `.usecsv_container { position: fixed; top: 0px; bottom: 0; right: 0; left: 0; z-index: 100000; }`
      : ``;

  insertCss(`
  ${usecsvContainerStyles}
  .usecsv_container iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    border-width: 0;
    top: 0;
    left: 0; 
  }
  .usecsv_container {
    overflow: hidden;
    overscroll-behavior-x: none;
  }
`);

  document.body.insertAdjacentHTML("beforeend", `<div id=${id} class="usecsv_container loading"></div>`);
  const iframe = document.createElement("iframe");
  iframe.setAttribute("src", MOUNT_URL);
  if (iframeId) {
    iframe.setAttribute("id", iframeId);
  }
  const usecsvContainer = document.getElementById(id);
  usecsvContainer?.appendChild(iframe);
  if (importerDisplay === "inline" && usecsvInlineWrapper) {
    // usecsvInlineWrapper > usecsvContainer > iframe
    usecsvInlineWrapper?.appendChild(usecsvContainer as HTMLElement);
  }

  return iframe;
};

const useCsvPlugin = ({
  importerKey,
  user,
  metadata,
  onData,
  onRecordsInitial,
  onRecordEdit,
  importerDisplay = "modal",
  onClose,
  theme,
  batchSize,
  sampleFileURL,
  downloadExampleButton,
  dynamicColumns,
  defaultLanguage,
  onError,
  customText,
  acceptedFileFormats,
  onFileUpload,
  columnWidth,
  onMatchColumns,
  iframeId,
}: {
  importerKey: string;
  user?: Record<string, string | number>;
  metadata?: Record<string, string | number>;
  onData?: (data: CsvData, close: () => void) => void | onDataResponseWithoutBatching | onDataResponseWithBatching;
  onRecordsInitial?: onRecordsInitialType;
  onRecordEdit?: onRecordEditType;
  importerDisplay?: "modal" | "inline";
  onClose?: () => void;
  theme?: ThemeType;
  batchSize?: number;
  sampleFileURL?: string;
  downloadExampleButton?: boolean;
  dynamicColumns?: DynamicColumnsType;
  defaultLanguage?: Language;
  onError?: onErrorType;
  customText?: customTextType;
  acceptedFileFormats?: Array<fileFormats>;
  onFileUpload?: onFileUploadType;
  columnWidth?: columnWidthType;
  onMatchColumns?: onMatchColumnsType;
  iframeId?: string;
}) => {
  const id = `usecsv-${Math.round(Math.random() * 100000000)}`;
  return whenDomReady().then(() => {
    const iframe = insertIframe(id, importerDisplay, iframeId);
    const closeIframe = () => {
      document.getElementById(id)?.remove();
    };
    const closeImporter = () => {
      if (onClose) onClose();
      return closeIframe();
    };
    const iframeConnection = connectToChild({
      iframe,
      methods: {
        closeIframe,
        ...(onData ? { onData: (data: CsvData) => onData(data, closeImporter) } : {}),
        ...(onRecordsInitial ? { onRecordsInitial } : {}),
        ...(onRecordEdit ? { onRecordEdit } : {}),
        ...(onClose ? { onClose } : {}),
        ...(onError ? { onError } : {}),
        ...(onFileUpload ? { onFileUpload } : {}),
        ...(onMatchColumns ? { onMatchColumns } : {}),
      },
    });
    iframeConnection.promise.then((child: any) => {
      document.getElementById(id)?.classList.remove("loading");
      // eslint-disable-next-line dot-notation
      child["setParams"] &&
        // eslint-disable-next-line dot-notation
        child["setParams"]({
          importerKey,
          user,
          metadata,
          importerDisplay,
          theme,
          batchSize,
          sampleFileURL,
          downloadExampleButton,
          dynamicColumns,
          defaultLanguage,
          customText,
          acceptedFileFormats,
          columnWidth,
        });
    });
    return iframeConnection;
  });
};

export default useCsvPlugin;
